import React, {useRef} from 'react';
import { Navigate } from "react-router-dom";

import { Typography } from 'antd';

import _auth from '@netuno/auth-client';

import PostCreate from '../../components/Post/Create';
import PostList from '../../components/Post/List';

import './index.less';

const { Title } = Typography;

export default function Home(props) {
  const refList = useRef();
  if (!_auth.isLogged()) {
    return <Navigate to="/login" />;
  }
  const onCreate = (post) => {
    refList.current.onNewPost(post);
  };
  return (
    <div className="home__container">
      <div className="home__create">
        <PostCreate onCreate={onCreate} type="post" />
      </div>
      <div className="home__list">
        <PostList ref={refList} type="post"/>
      </div>
    </div>
  );
}
