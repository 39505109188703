import MDEditor, {commands} from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

import './index.less';

function ContentEditor({value, onChange}) {
  return (
    <div className="content-editor" data-color-mode="dark">
      <MDEditor
        preview="edit"
        commands={[
          commands.title, 
          commands.bold, 
          commands.italic, 
          commands.code, 
          commands.codeBlock, 
          commands.quote,
          commands.hr
        ]}
        value={value}
        onChange={onChange}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]],
        }}
        height={300}
        visibleDragbar={false}
      />
    </div>
  );
}

export default ContentEditor;
