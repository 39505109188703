import React, {useState} from 'react';

import {Button, Popover} from 'antd';

import { AiFillEdit } from 'react-icons/ai';

import Editor from '../Editor';

function Update({type, data, onUpdate, onRemove}) {
  const [start, setStart] = useState(false);
  const onClose = () => {
    setStart(false);
  };
  const onUpdated = (content) => {
    onClose();
    if (onUpdate) {
      onUpdate(content);
    }
  };
  const onRemoved = () => {
    onClose();
    if (onRemove) {
      onRemove();
    }
  };
  return (
    <div>
      <Popover content="Editar publicação">
        <a onClick={() => setStart(true)}><AiFillEdit/></a>
      </Popover>
      {start && <Editor type={type} data={data} onClose={onClose} onUpdated={onUpdated} onRemoved={onRemoved} />}
    </div>
  );
}

export default Update;
