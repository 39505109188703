import Reat, {useState, useEffect, useRef} from 'react';

import $ from 'jquery';

import moment from 'moment';

import {Link} from 'react-router-dom';

import MDEditor, {commands} from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

import {Row, Col, Space, Button, Popover} from 'antd';

import { 
  CaretDownOutlined, CaretUpOutlined
} from '@ant-design/icons';

import { BsChatRightFill } from 'react-icons/bs';

import { MdOpenInNew } from "react-icons/md";

import Update from './Update';
import Like from './Like';

import Comments from './Comments';

import './index.less';

function Post({type, data, onRemove}) {
  const [content, setContent] = useState(data.content);
  const [short, setShort] = useState(true);
  const [like, setLike] = useState(false);
  const [comments, setComments] = useState(false);

  const refContent = useRef(null);
  const refExpand = useRef(null);
  const refReduce = useRef(null);
  
  useEffect(()=> {
    if (refContent && refContent.current
        && refExpand && refExpand.current) {
      if (refContent.current.scrollHeight > refContent.current.clientHeight) {
        refExpand.current.style.display = 'block';
      } else {
        refExpand.current.style.display = 'none';
      }
    }
  }, []);

  const onUpdate = (newContent) => {
    data.content = newContent;
    setContent(newContent);
  };

  const onUpdateRemove = () => {
    if (onRemove) {
      onRemove(data.uid);
    }
  };

  const onExpand = () => {
    const $content = $(refContent.current);
    $content.removeClass('post__content--short');
    $(refExpand.current).hide();
    $(refReduce.current).fadeIn();
  };

  const onReduce = () => {
    const $content = $(refContent.current);
    $content.addClass('post__content--short');
    $(refReduce.current).hide();
    $(refExpand.current).fadeIn();
  };

  return (
    <div className="post">
      <Row className="post__info">
        <Col span={12} className="post__info__user">
          <Link to={`/d/${data.user}`}>{data.user}</Link>
        </Col>
        <Col span={12} align="right" className="post__info__time">
          {moment(data.moment, "YYYY-MM-DD hh:mm:ss.S").format("hh:mm DD/MM/YY")}
        </Col>
      </Row>
      <div ref={refContent} data-color-mode="dark" className="post__content post__content--short">
        <MDEditor.Markdown source={content} />
      </div>
      <Row className="post__actions">
        <Col span={4}>
          <Space size="middle">
            <Link to={`/p/${data.uid}`}><MdOpenInNew /></Link>
            <span>
              <a ref={refExpand} onClick={onExpand}><CaretDownOutlined /></a>
              <a ref={refReduce} onClick={onReduce} style={{display: 'none'}}><CaretUpOutlined /></a>
            </span>
            {
              data.own &&
                <Update type={type} data={data} onUpdate={onUpdate} onRemove={onUpdateRemove}/>
            }
          </Space>
        </Col>
        <Col span={16} align="center">
          <Like data={data}/>
        </Col>
        <Col span={4} align="right">
          <Popover content="Ver comentários">
            <a onClick={() => setComments(!comments)} className="post__actions__comments">
              <BsChatRightFill />
              <span>{data.comments}</span>
            </a>
          </Popover>
        </Col>
      </Row>
      {comments && <Comments parent={data.uid}/>}
    </div>
  );
}

export default Post;
