import React from 'react';

function Devs() {
  return (
    <div>
      devs
    </div>
  );
}

export default Devs;
