import React, {useState, useEffect} from 'react';

import _service from '@netuno/service-client';

import PostList from '../../../../components/Post/List';

function Posts() {
  return (
    <div className="dev__activities__posts__container">
      <div className="dev__activities__posts__list">
        <PostList type="post" activities/>
      </div>
    </div>
  );
}

export default Posts;
