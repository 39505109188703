import Reat, {useState, useEffect, useRef} from 'react';

import PostCreate from '../Create';
import PostList from '../List';

import './index.less';

function Comments({parent}) {
  const refList = useRef();
  const onCreate = (post) => {
    refList.current.onNewPost(post);
  };
  return (
    <div className="post__comment__container">
      <div className="post__comment__create">
        <PostCreate type="comment" parent={parent} onCreate={onCreate} />
      </div>
      <div className="post__comment__list">
        <PostList ref={refList} parent={parent} type="comment"/>
      </div>
    </div>
  );
}

export default Comments;
