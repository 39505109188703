import React from "react";

import Posts from "./Posts";

function Activities() {
  return (
    <div>
      <Posts/>
    </div>
  );
}

export default Activities;
