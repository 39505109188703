import React, {useEffect, useState, useImperativeHandle} from 'react';

import MDEditor from '@uiw/react-md-editor';

import {List, Button, notification} from 'antd';

import Masonry from 'react-masonry-css';

import _service from '@netuno/service-client';

import Post from '../';

import './index.less';

function PostList({type, parent, activities}, ref) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  useEffect(() => {
    onLoad();
  }, []);
  const onLoad = () => {
    _service({
      method: 'GET',
      url: activities ? 'dev/activity/post/list' : 'post/list',
      data: {type, parent, page},
      success: (response) => {
        setInitLoading(false);
        setLoading(false);
        setList([...list, ...response.json]);
        setPage(page + 1);
      },
      fail: (error) => {
        console.log(error);
        setInitLoading(false);
        setLoading(false);
        notification.error({
          message: 'Publicações',
          description: 'Não foi possível obter mais publicações, volte a tentar mais tarde.',
        });
      }
    });
  };

  const onRemove = (uid) => {
    setList(list.filter((item) => item.uid != uid));
  };

  const onNewPost = (post) => {
    setList([post, ...list]);
  };

  useImperativeHandle(ref, () => ({
    onNewPost
  }));

  const loadMore = !initLoading && !loading && list.length % 25 == 0 && (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button type="link" onClick={onLoad}>
        {type == 'post' && 'carregar mais publicações'}
        {type == 'comment' && 'carregar outros comentários'}
      </Button>
    </div>
  );

  if (type == 'comment') {
    if (list.length == 0) {
      return null;
    }
    return (
      <List
        className="post__list__comments"
        loading={initLoading}
        loadMore={loadMore}
        dataSource={list}
        renderItem={(item) => (
          <List.Item>
            <Post key={item.uid} type="comment" data={item} onRemove={onRemove}/>
          </List.Item>
        )}
      />
    );
  } else {
    return (
      <Masonry
        breakpointCols={{
          default: 3,
          1600: 2,
          1100: 1
        }}
        className="post__list"
        columnClassName="post__list__column">
        {
          list.map((item) => {
            return <Post key={item.uid} type="post" data={item} onRemove={onRemove}/>;
          })
        }
      </Masonry>
    );
  }
}

export default React.forwardRef(PostList);
