import Reat, {useState, useEffect, useRef} from 'react';

import $ from 'jquery';

import {Row, Col, Space, Button, Popover, Typography, Spin, notification} from 'antd';

import _service from '@netuno/service-client';

import { AiFillLike, AiOutlineLike, AiFillDislike, AiOutlineDislike, AiOutlineClose } from 'react-icons/ai';
import { IoRocketSharp } from 'react-icons/io5';
import { GiPartyPopper } from 'react-icons/gi';
import { BsHeartFill } from 'react-icons/bs';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { RiEmotionLaughFill, RiEmotionSadFill } from 'react-icons/ri';
import { GoAlert } from 'react-icons/go';

import LikeList from './List';

import Core from '../../../common/Core.js';

import './index.less';

const { Text } = Typography;

function Like({data}) {
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(data.liked);
  const [likes, setLikes] = useState(data.likes);
  const [rockets, setRockets] = useState(data.rockets);
  const [parties, setParties] = useState(data.parties);
  const [hearts, setHearts] = useState(data.hearts);
  const [supports, setSupports] = useState(data.supports);
  const [laughs, setLaughs] = useState(data.laughs);
  const [sads, setSads] = useState(data.sads);
  const [alerts, setAlerts] = useState(data.alerts);
  
  const refLikeList = useRef();

  const setState = (key, plus) => {
    key = Core.singularCodeToPlural(key);
    eval(
      'set'+ key.substring(0, 1).toUpperCase() + key.substring(1)
        +'('+ key +' '+ (plus ? '+' : '-') +' 1);'
        +'data.'+ key +' '+ (plus ? '+' : '-') +'= 1;'
    );
  };

  const onLike = (type) => {
    setLoading(true);
    _service({
      method: 'POST',
      url: 'post/like',
      data: {
        type,
        post: data.uid
      },
      success: ({json}) => {
        const { oldType } = json;
        if (oldType != type) {
          if (oldType && oldType != '') {
            setState(oldType, false);
          }
          setState(type, true);
        }
        setLiked(true);
        setLoading(false);
      },
      fail: (error) => {
        setLoading(false);
        notification.error({
          message: 'Falha ao Gostar',
          description: 'Não foi possível gostar, tente novamente mais tarde.',
        });
      }
    });
  };

  const onLikeRemove = () => {
    setLoading(true);
    _service({
      method: 'DELETE',
      url: 'post/like',
      data: {
        post: data.uid
      },
      success: ({json}) => {
        const { oldType } = json;
        if (oldType && oldType != '') {
          setState(oldType, false);
          setLiked(false);
        }
        setLoading(false);
      },
      fail: (error) => {
        setLoading(false);
        notification.error({
          message: 'Falha ao Remover',
          description: 'Não foi possível remover o gostar, tente novamente mais tarde.',
        });
      }
    });
  };
  
  const onLikeStatus = () => {
    refLikeList.current.onShow();
  };
  return (
    <Space className="post__like">
      <Popover content={
        <>
          {loading ? <Spin/> :
           <Space>
             <Popover content="Gosto">
               <a onClick={() => onLike('like')}><AiFillLike/></a>
             </Popover>
             <Popover content="Conquista">
               <a onClick={() => onLike('rocket')}><IoRocketSharp/></a>
             </Popover>
             <Popover content="Celebração">
               <a onClick={() => onLike('party')}><GiPartyPopper/></a>
             </Popover>
             <Popover content="Amei">
               <a onClick={() => onLike('heart')}><BsHeartFill/></a>
             </Popover>
             <Popover content="Apoio">
               <a onClick={() => onLike('support')}><FaHandHoldingHeart/></a>
             </Popover>
             <Popover content="Engraçado">
               <a onClick={() => onLike('laugh')}><RiEmotionLaughFill/></a>
             </Popover>
             <Popover content="Lamento">
               <a onClick={() => onLike('sad')}><RiEmotionSadFill/></a>
             </Popover>
             <Popover content="Alerta">
               <a onClick={() => onLike('alert')}><GoAlert/></a>
             </Popover>
             {liked && <Popover content="Remover Like">
                       <a onClick={() => onLikeRemove()}>
                         <Text type="danger">
                           <AiOutlineClose/>
                         </Text>
                       </a>
                     </Popover>}
           </Space>
          }
        </>
      }>
        <a className="post__like__link">
          {liked ? null : null} Gostar
        </a>
      </Popover>
      <a onClick={onLikeStatus} className="post__like__status">
        <Space size="small" className="post__like__status__details">
          {likes > 0 && <span><AiFillLike/><span>{likes}</span></span>}
          {rockets > 0 && <span><IoRocketSharp/><span>{rockets}</span></span>}
          {parties > 0 && <span><GiPartyPopper/><span>{parties}</span></span>}
          {hearts > 0 && <span><BsHeartFill/><span>{hearts}</span></span>}
          {supports > 0 && <span><FaHandHoldingHeart/><span>{supports}</span></span>}
          {laughs > 0 && <span><RiEmotionLaughFill/><span>{laughs}</span></span>}
          {sads > 0 && <span><RiEmotionSadFill/><span>{sads}</span></span>}
          {alerts > 0 && <span><GoAlert/><span>{alerts}</span></span>}
        </Space>
      </a>
      <LikeList ref={refLikeList} data={data}/>
    </Space>
  );
}

export default Like;
