import React from 'react';

function Communities() {
  return (
    <div>
      comunidades
    </div>
  );
}

export default Communities;
