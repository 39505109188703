import React from 'react';

function Explore() {
  return (
    <div>
      explorar
    </div>
  );
}

export default Explore;
