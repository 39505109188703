import Reat, {useState, useRef} from 'react';

import MDEditor, {commands} from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

import {Modal, Button, Typography, Space, Popconfirm, Select, notification} from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import _service from '@netuno/service-client';

import ContentEditor from '../../ContentEditor';

import './index.less';

const { Text } = Typography;

const CONTENT_MAX_LENGTH = 2500;

function Editor({type, parent, data, onClose, onCreated, onUpdated, onRemoved}) {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(data ? data.content : '');
  const [tagLoading, setTagLoading] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const refTag = useRef(null);
  const text = ({post, comment}) => {
    if (type == 'post') {
      return post;
    }
    if (type == 'comment') {
      return comment;
    }
    return type;
  };
  const onPublish = () => {
    if (content.length > CONTENT_MAX_LENGTH) {
      notification.warning({
        message: 'Conteúdo Longo',
        description: 'O conteúdo não pode conter mais que 2.500 caractéres.',
      });
      return;
    }
    setLoading(true);
    if (data) {
      _service({
        method: 'PUT',
        url: 'post',
        data: {
          uid: data.uid,
          content,
          tags
        },
        success: (response) => {
          setLoading(false);
          if (onUpdated) {
            onUpdated(content);
          }
        },
        fail: (error) => {
          setLoading(false);
          notification.error({
            message: text({post: 'Falha na Publicação', comment: 'Falha no Comentário'}),
            description: 'Não foi possível corrigir, tente novamente mais tarde.',
          });
        }
      });
    } else {
      _service({
        method: 'POST',
        url: 'post',
        data: {
          type,
          content,
          parent,
          tags
        },
        success: (response) => {
          notification.success({
            message: 'Publicado',
            description: type == 'post' ? 'A sua nova publicação está pronta.' : type == 'comment' ? 'O seu novo comentário está pronto.' : null,
          });
          setLoading(false);
          if (onCreated) {
            onCreated(response.json);
          }
        },
        fail: (error) => {
          setLoading(false);
          notification.error({
            message: text({post: 'Falha na Publicação', comment: 'Falha no Comentário'}),
            description: 'Não foi possível publicar, tente novamente mais tarde.',
          });
        }
      });
    }
  };
  const onCancel = () => {
    if (onClose) {
      onClose();
    }
  };
  const onRemove = () => {
    _service({
      method: 'DELETE',
      url: 'post',
      data: {
        uid: data.uid
      },
      success: (response) => {
        setLoading(false);
        if (onRemoved) {
          onRemoved(content);
        }
      },
      fail: (error) => {
        setLoading(false);
        notification.error({
          message: text({post: 'Falha na Publicação', comment: 'Falha no Comentário'}),
          description: 'Não foi possível remover, tente novamente mais tarde.',
        });
      }
    });
  };
  const onTagSearch = (value) => {
    _service({
      method: 'GET',
      url: 'tag/search',
      data: {
        name: value
      },
      success: ({json}) => {
        setTagLoading(false);
        setTagOptions(json);
      },
      fail: (error) => {
        setTagLoading(false);
        notification.error({
          message: text({post: 'Falha na Publicação', comment: 'Falha nas Etiquetas'}),
          description: 'Não foi possível listar as etiquetas, tente novamente mais tarde.',
        });
      }
    });
  };
  const onTagChange = (value) => {
    value = value.filter((v) => {
      return /^[a-z0-9]+$/.test(v);
    });
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    setTags(value);
  };
  return (
    <Modal
      title={
        data 
          ? text({post: 'Editar publicação', comment: 'Editar comentário'})
          : text({post: 'Criar uma nova publicação', comment: 'Criar um novo comentário'})
      }
      visible={true}
      onCancel={onCancel}
      footer={[
        <Space size="large">
          { data &&
            <Popconfirm
              title={<p>Tem a certeza que deseja <br/>remover {text({post: 'está publicação', comment: 'este comentário'})}?</p>}
              onConfirm={onRemove}
              okText="Sim"
              cancelText="Não"
              okButtonProps={{type: 'danger', style: {height: 'auto'}}}
              cancelButtonProps={{type: 'primary', style: {height: 'auto'}}}
            >
              <Button type="danger" icon={<DeleteFilled />} />
            </Popconfirm>
          }
          <Text type={content.length > CONTENT_MAX_LENGTH ? 'danger' : 'default'}>
            {content.length}/{CONTENT_MAX_LENGTH}
          </Text>
          <Button key="publish" type="primary" disabled={content.length > CONTENT_MAX_LENGTH} loading={loading} onClick={onPublish}>
            {data ? 'Atualizar' : 'Publicar'}
          </Button>
        </Space>
      ]}
    >
      <div className="post__editor">
         <ContentEditor value={content} onChange={setContent} />
        {JSON.stringify(tagOptions)}
          <Select
            ref={refTag}
            mode="tags"
            loading={tagLoading}
            style={{
              width: '100%',
            }}
            placeholder="Etiquetas"
            onSearch={onTagSearch}
            onChange={onTagChange}
            value={tags}
            options={[]}
            onInputKeyDown={(e) => { if (e.keyCode == 13) {
              refTag.current.blur();
              setTimeout(() => refTag.current.focus(), 250);
            }
            }}
          />
      </div>
    </Modal>
  );
}

export default Editor;
