import React, {useState} from 'react';

import {Button} from 'antd';

import Editor from '../Editor';

function Create({type, parent, onCreate}) {
  const [start, setStart] = useState(false);
  const onClose = () => {
    setStart(false);
  };
  const onCreated = (data) => {
    onClose();
    if (onCreate) {
      onCreate(data);
    }
  };
  return (
    <div>
      <Button type="dashed" onClick={() => setStart(true)}>
        {
          type == 'post' ? <>Iniciar uma publicação</>
          : type == 'comment' ? <>Adicionar comentário</> : null
        }
      </Button>
      {start && <Editor type={type} parent={parent} onClose={onClose} onCreated={onCreated} />}
    </div>
  );
}

export default Create;
