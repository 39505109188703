import React, {useState, useEffect} from 'react';

import _service from '@netuno/service-client';

import { Typography, Row, Col, Divider, notification, Spin } from 'antd';

import {
  useParams
} from "react-router-dom";

import Post from '../../components/Post';

import './index.less';

const { Title } = Typography;

function PostPage() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { uid } = useParams();
  
  useEffect(() => {
    _service({
      method: 'GET',
      url: 'post',
      data: { uid },
      success: (response) => {
        setLoading(false);
        if (response.json.result) {
          setData(response.json.data);
        } else {
          notification["warning"]({
            message: 'Alerta da Matrix',
            description: response.json.error,
          });
          setLoading(false);
        }
      },
      fail: () => {
        setLoading(false);
        notification["error"]({
          message: 'Bug da Matrix',
          description: 'Ocorreu um erro ao carregar os dados, por favor tente novamente mais tarde.',
        });
      }
    });
  }, []);

  if (loading) {
    return (
      <div className="loading-wrapper">
        <div className="content-title">
          <Title level={2}><Spin /> carregando...</Title>
        </div>
      </div>
    );
  }
  
  if (data == null) {
    return (
      <div className="dev__container">
        <Title level={2}>N&atilde;o encontrado.</Title>
      </div>
    );
  }

  return (
    <div className="post__container">
      <Post key={data.uid} type="post" data={data} onRemove={() => {}}/>
    </div>
  );
}

export default PostPage;
