import React, {useState, useEffect} from 'react';

import _service from '@netuno/service-client';

import {List, Button, notification} from 'antd';

import './index.less';

function TabContent({data, type}) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    onLoad();
  }, []);
  
  const onLoad = () => {
    setLoading(true);
    _service({
      method: 'GET',
      url: 'post/like/list',
      data: {type, post: data.uid, page},
      success: (response) => {
        setInitLoading(false);
        setLoading(false);
        setList([...list, ...response.json]);
        setPage(page + 1);
      },
      fail: (error) => {
        console.log(error);
        setInitLoading(false);
        setLoading(false);
        notification.error({
          message: 'Listagem de Gostos',
          description: 'Não foi possível obter a listagem de gostos, volte a tentar mais tarde.',
        });
      }
    });
  };

  const loadMore = !initLoading && !loading && list.length % 25 == 0 && (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button type="link" onClick={onLoad}>carregar mais gostos</Button>
    </div>
  );

  return (
    <List
      className="post__like__list"
      loading={initLoading}
      loadMore={loadMore}
      dataSource={list}
      renderItem={(user) => (
        <List.Item><a>{user}</a></List.Item>
      )}
    />
  );
}

export default TabContent;

