
class Core {
  static singularCodeToPlural(code) {
    let key = code + 's';
    if (key.endsWith('ys')) {
      key = key.substring(0, key.lastIndexOf('y')) + 'ies';
    }
    return key;
  }
}

export default Core;
