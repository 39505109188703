import React, {useState, useImperativeHandle} from 'react';

import {Modal, Tabs, Popover} from 'antd';

import _service from '@netuno/service-client';

import { AiFillLike, AiOutlineLike, AiFillDislike, AiOutlineDislike, AiOutlineClose } from 'react-icons/ai';
import { IoRocketSharp } from 'react-icons/io5';
import { GiPartyPopper } from 'react-icons/gi';
import { BsHeartFill } from 'react-icons/bs';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { RiEmotionLaughFill, RiEmotionSadFill } from 'react-icons/ri';
import { GoAlert } from 'react-icons/go';

import TabContent from './TabContent';

import Core from '../../../../common/Core.js';

import './index.less';

const {TabPane} = Tabs;

function LikeList({data}, ref) {
  const [show, setShow] = useState(false);
  const onCancel = () => {
    setShow(false);
  };
  const onShow = () => {
    setShow(true);
  };
  useImperativeHandle(ref, () => ({
    onShow
  }));
  const buildTabPane = (code, name) => {
    return (
      <TabPane key={code} tab={
        <Popover content={name}>
          <a className="post__like__tab">
            {{
              like: <AiFillLike/>,
              rocket: <IoRocketSharp/>,
              party: <GiPartyPopper/>,
              heart: <BsHeartFill/>,
              support: <FaHandHoldingHeart/>,
              laugh:<RiEmotionLaughFill/>,
              sad:<RiEmotionSadFill/>,
              alert:<GoAlert/>,
            }[code]}
            <span>{data[Core.singularCodeToPlural(code)]}</span>
          </a>
        </Popover>
      }>
        <TabContent data={data} type={code} />
      </TabPane>
    );
  };
  return (
    <Modal title="Lista dos Gostos" visible={show} onCancel={onCancel} footer={null}>
      <Tabs defaultActiveKey="like" centered>
        {data.likes > 0 && buildTabPane('like', 'Gosto')}
        {data.rockets > 0 && buildTabPane('rocket', 'Conquista')}
        {data.parties > 0 && buildTabPane('party', 'Celebração')}
        {data.hearts > 0 && buildTabPane('heart', 'Amei')}
        {data.supports > 0 && buildTabPane('support', 'Apoio')}
        {data.laughs > 0 && buildTabPane('laugh', 'Engraçado')}
        {data.sads > 0 && buildTabPane('sad', 'Lamento')}
        {data.alerts > 0 && buildTabPane('alert', 'Alerta')}
      </Tabs>
    </Modal>
  );
}

export default React.forwardRef(LikeList);
