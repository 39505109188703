import React from 'react';

import {
  useParams
} from "react-router-dom";

function Community() {
  let {community} = useParams();
  return (
    <div>
      {community}
    </div>
  );
}

export default Community;
