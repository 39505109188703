import React, {useState, useEffect} from 'react';

import _service from '@netuno/service-client';

import { Typography, Row, Col, Divider, notification, Spin } from 'antd';

import {
  useParams
} from "react-router-dom";

import Activities from "./Activities";

import './index.less';

const { Title } = Typography;

function Dev() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [avatarImageURL, setAvatarImageURL] = useState('/images/profile-default.png');
  const { user } = useParams();
  
  useEffect(() => {
    _service({
      method: 'GET',
      url: 'dev',
      data: { user },
      success: (response) => {
        setLoading(false);
        if (response.json.result) {
          setData(response.json.data);
        } else {
          notification["warning"]({
            message: 'Alerta da Matrix',
            description: response.json.error,
          });
          setLoading(false);
        }
      },
      fail: () => {
        setLoading(false);
        notification["error"]({
          message: 'Bug da Matrix',
          description: 'Ocorreu um erro ao carregar os dados, por favor tente novamente mais tarde.',
        });
      }
    });
  }, []);

  useEffect(() => {
    if (data && data.avatar) {
      setAvatarImageURL(null);
      setTimeout(() => setAvatarImageURL(`${_service.config().prefix}/people/avatar?uid=${data.uid}&${new Date().getTime()}`), 250);
    }
  }, [data]);

  if (loading) {
    return (
      <div className="loading-wrapper">
        <div className="content-title">
          <Title level={2}><Spin /> carregando...</Title>
        </div>
      </div>
    );
  }
  
  if (data == null) {
    return (
      <div className="dev__container">
        <Title level={2}>N&atilde;o encontrado.</Title>
      </div>
    );
  }

  return (
    <div className="dev__container">
      <Row>
        <Col span={24}>
          <div className="dev__user-info">
            <div className="dev__user-info__avatar">
              {avatarImageURL && <img src={avatarImageURL}/>}
            </div>
            <Title level={3}>{data.username}</Title>
          </div>
        </Col>
      </Row>
      <Activities/>
    </div>
  );
}

export default Dev;
