import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation, Link, Navigate } from "react-router-dom";

import {LinkedinOutlined, TwitterOutlined, InstagramOutlined, FacebookOutlined} from '@ant-design/icons';

import { ConfigProvider, Layout, Menu, Button, Row, Col, notification } from 'antd';
import { PieChartOutlined, UserOutlined, LogoutOutlined, MenuOutlined, EditOutlined } from '@ant-design/icons';

import { BiWorld } from 'react-icons/bi';
import { RiTeamFill, RiDashboardFill } from 'react-icons/ri';
import { FaCampground } from 'react-icons/fa';
import { TbTriangleSquareCircle } from 'react-icons/tb';

import antLocale_ptPT from 'antd/lib/locale/pt_PT';

import { Provider } from 'react-redux';
import { Store } from './redux/store';

import classNames from 'classnames';

import _auth from '@netuno/auth-client';
import './common/Config';

import HeaderUserInfo from './components/HeaderUserInfo';

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import RecoveryPage from './pages/Recovery';
import ProfileEdit from './pages/Profile/Edit';
import Dev from './pages/Dev';
import Devs from './pages/Devs';
import Community from './pages/Community';
import Communities from './pages/Communities';
import Post from './pages/Post';
import Explore from './pages/Explore';
import NotFoundPage from './pages/NotFound';
import Home from './pages/Home';

import './styles/App.less';

const { Header, Content, Sider, Footer } = Layout;
const { SubMenu } = Menu;

notification.config({
  placement: 'bottomLeft',
  bottom: 150,
  duration: 5,
});

const NavCheck = () => {
  if (_auth.isLogged()) {
    return (
      <Navigate to="/home" />
    );
  } else {
    return(
      <Navigate to="/register" />
    );
  }
};

export default function App(props) {

  const location = useLocation();

  const [headerButtonMode, setHeaderButtonMode] = useState('login');
  const [collapsed, setCollapsed] = useState(false);
  const [sideMenuMobileMode, setSideMenuMobileMode] = useState(false);

  useEffect(() => {
    setHeaderButtonMode(location.pathname);
  }, [location]);

  function onLogout() {
    _auth.logout();
  }

  function onCollapse() {
    setCollapsed(!collapsed);
  }

  return (
    <ConfigProvider locale={antLocale_ptPT}>
      <Provider store={Store}>
        <Layout className={'page ' + classNames({ 'auth ': _auth.isLogged() }) + classNames({ 'collapsed ': collapsed })}>
          {_auth.isLogged() &&
           <Sider
             onBreakpoint={mobile => {
               setSideMenuMobileMode(mobile);
             }}
             collapsedWidth={sideMenuMobileMode ? '0' : '80'}
             breakpoint={"md"}
             collapsible
             collapsed={collapsed}
             onCollapse={onCollapse}
             trigger={<MenuOutlined />}
           >
             <div className="logo-container"><img alt="logo" src="/images/logo.svg" /></div>
             <Menu defaultSelectedKeys={['1']} mode="inline">
               <Menu.Item key="1" icon={<RiDashboardFill />}>
                 <Link to="/home">Início</Link>
               </Menu.Item>
               <Menu.Item key="2" icon={<RiTeamFill />}>
                 <Link to="/devs">Devs</Link>
               </Menu.Item>
               <Menu.Item key="3" icon={<FaCampground />}>
                 <Link to="/communities">Comunidades</Link>
               </Menu.Item>
               <Menu.Item key="4" icon={<BiWorld />}>
                 <Link to="/explore">Explorar</Link>
               </Menu.Item>
             </Menu>
           </Sider>
          }
          <Layout>
            <Header className={classNames({ 'auth ': _auth.isLogged() }) + classNames({ 'collapsed ': collapsed })}>
              {!_auth.isLogged() &&
               <Link to="/" className="logo-container"><img alt="logo" src="/images/logo.svg" /></Link>
              }
              <Menu mode="horizontal">
                {headerButtonMode === '/login' ?
                 <Link to="/register">
                   <Button type="primary">Criar conta</Button>
                 </Link>
                 : headerButtonMode === '/register' ?
                 <Link to="/login">
                   <Button type="primary">Iniciar sessão</Button>
                 </Link>
                 : _auth.isLogged() &&
                 <SubMenu key={"profile"} className="profile-menu" popupClassName="profile-menu-popup" title={<HeaderUserInfo />}>
                   <Menu.Item key="1">
                     <Link to="/profile/edit">
                       <EditOutlined /> Editar Perfil
                     </Link>
                   </Menu.Item>
                   <Menu.Item key="2">
                     <Link to="/login">
                       <Button type="link" onClick={onLogout} danger>
                         <LogoutOutlined /> Terminar Sessão
                       </Button>
                     </Link>
                   </Menu.Item>
                 </SubMenu>
                }
              </Menu>
            </Header>
            <Content className={classNames({ 'auth ': _auth.isLogged() })}>
              <Switch>
                <Route exact path="/" element={<NavCheck/>}/>
                <Route path="/home" element={<Home/>} />`
                <Route path="/devs" element={<Devs/>} />
                <Route path="/d/:user" element={<Dev/>} />
                <Route path="/communities" element={<Communities/>} />
                <Route path="/c/:user" element={<Community/>} />
                <Route path="/p/:uid" element={<Post/>} />
                <Route path="/explore" element={<Explore/>} />
                <Route path="/profile/edit" element={<ProfileEdit/>} />
                <Route path="/login" element={<LoginPage/>} />
                <Route path="/register" element={<RegisterPage/>} />
                <Route path="/recovery" element={<RecoveryPage/>} />
                <Route path="*" element={<NotFoundPage/>} />
              </Switch>
            </Content>
            <Footer className={!_auth.isLogged() && 'footer--public'}>
              <Row align="middle">
                <Col span={12}>
                  <a href="https://www.linkedin.com/company/vira-dev/" target="_blank"><LinkedinOutlined /></a>
                  <a href="https://twitter.com/viradeveloper" target="_blank"><TwitterOutlined /></a>
                  <a href="https://www.instagram.com/vira.developer/" target="_blank"><InstagramOutlined /></a>
                  <a href="https://www.facebook.com/vira.developer" target="_blank"><FacebookOutlined /></a>
                  <a href="https://discord.gg/RBc8kSyHAB" target="_blank"><FacebookOutlined /></a>
                </Col>
                <Col span={12}>
                  © vira.dev {new Date().getFullYear()}
                </Col>
              </Row>
            </Footer>
          </Layout>
        </Layout>
      </Provider>
    </ConfigProvider>
  );
}
